.section__row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.analiys__section {
  width: 48%;
  height: 450px;
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center;
}

.analisys__block {
  width: 80%;
  margin: auto;
}

.analisys__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.diagram {
  width: 48%;
  height: 300px;
  margin: 20 30 20 5;
}
