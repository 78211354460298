.section__row
  flex-direction: row
  gap: 10px
  flex-wrap: wrap
.analiys__section
  width: 48%;
  height: 450px;
  border: 1px solid grey;
  border-radius: 10px;
  text-align: center; 
.analisys__block
  width: 80%
  margin: auto;
.analisys__item
  display: flex
  justify-content: space-between
.diagram
  width: 48%
  height: 300px 
  margin: 20 30 20 5 